@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.row {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.loader {
  width: 100vw;
  height: 100vh;
}

.btn-secondary {
  background-color: white;
  border-radius: 0;
  color: black;
  border-color: transparent;
  padding: 10px;
  width: 100%;
  font-family: "Fira Sans";
}

.btn-primary {
  background-color: #0067ec;
  width: fit-content;
  color: white;
  border: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Fira Sans";
  font-weight: 500;
  gap: 10px;
  padding: 20px 30px;
}

.btn-primary .btn-icon {
  height: 20px;
  width: fit-content;
}

p,
h5,
h3,
h1,
h2,
h4,
h6 {
  padding: 0;
  margin: 0;
}

h6 {
  font-family: "Kanit";
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 140%;
}

img {
  width: 100%;
  height: 100%;
}

section,
footer {
  padding: 0 15vh;
  min-height: fit-content;
}

p.small {
  font-size: 1rem;
}

body {
  margin: 0;
  padding: 0;
  background-color: #3b4961 !important;
  color: white;
  overflow-x: hidden;
}

p {
  font-family: "Inter";
  font-weight: 350;
}

@media (max-width: 575.98px) {
  section,
  footer {
    height: fit-content;
    padding: 0 3vh;
  }
  .btn-primary {
    width: 100%;
  }
  .row {
    height: fit-content;
  }
  h5,
  h3,
  h4,
  h1,
  h2 {
    font-family: "Kanit";
  }
  p {
    width: 100%;
  }
}

@media (min-width: 576px) {
  section,
  footer {
    padding: 0 2vh;
  }
  .btn-primary {
    width: 100%;
  }
}

@media (min-width: 768px) {
  footer {
    padding: 0 7vh;
  }
  section {
    height: 120vh;
    padding: 0 7vh;
  }
  .btn-primary {
    width: fit-content;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  section {
    height: 120vh;
    padding: 0 15vh;
  }
  footer {
    padding: 0 15vh;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #3b4961;
    color: white;
  }

  h1 {
    font-family: "Kanit";
    font-size: 6rem;
    font-weight: 600;
    margin: 0;
  }
  h2 {
    font-family: "Kanit";
    font-size: 5rem;
    font-weight: 600;
    margin: 0;
  }

  h3 {
    font-family: "Kanit";
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
  }

  h5 {
    font-family: "Kanit";
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
  }

  p {
    font-size: 1.2rem;
    line-height: 140%;
  }

  .row {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1400px) {
  section {
    height: 120vh;
    padding: 0 25vh;
    min-height: fit-content;
  }
  footer {
    padding: 0 25vh;
  }

  h1 {
    font-family: "Kanit";
    font-size: 4rem;
    font-weight: 600;
    margin: 0;
  }

  h3 {
    font-family: "Kanit";
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
  }

  h5 {
    font-family: "Kanit";
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
  }

  p {
    font-family: "Inter";
    font-size: 1.1rem;
    line-height: 140%;
  }
}
