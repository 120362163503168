header {
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #245aa6   ;
  display: flex;
  align-items: center;
  border-bottom: 0.2px solid white;
  font-weight: 500;
  .logo {
    width: auto !important;
    height: 80%;
  }
  .btn-nav {
    background-color: #0067ec;
  }

  .nav-item {
    a {
      text-decoration: none;
      color: white;
    }
  }
  @media (max-width: 575.98px) {
    padding: 0 4vh;

    height: 70px;
    .logo {
      width: 60px !important;
      height: 80%;
    }
  }

  @media (min-width: 576px) {
    padding: 0 2vh;

    height: 70px;
  }

  @media (min-width: 768px) {
    padding: 0 7vh;
    .logo {
      height: 100%;
    }
    height: 90px;
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
    padding: 0 15vh;
  }

  @media (min-width: 1400px) {
    padding: 0 25vh;
  }
}
