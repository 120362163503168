.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  overflow: hidden;
  max-height: 100vh;
  width: 100%;
}

.hero-section::before {
  content: "";
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.7;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7) 40%,
      rgba(0, 0, 0, 0) 90%
    ),
    url("/img/hero-section_bg.webp");
  background-position: center;
  background-size: cover;
  transition: opacity 0.5s ease;
}

.hero-content {
  position: relative;
  z-index: 2;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.story-section {
  display: flex;
  align-items: center;
  justify-items: center;
  padding-top: 20vh;
  padding-bottom: 20vh;
  img {
    object-fit: cover;
  }
}

.services-section {
  height: fit-content;

  padding-top: 15vh;
  padding-bottom: 15vh;
  background-color: #0067ec;
  .card-service {
    background-color: #245aa6;
    color: white;
    border-radius: 0;
    .card-img-top {
      border-radius: 0;
    }
  }
}

.makes-us-special-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0067ec;
  padding-top: 20vh;
  padding-bottom: 20vh;
  img {
    object-fit: cover;
  }
  .advantage {
    height: 50px;
    align-items: center;
    .icon {
      width: auto;
      height: 50%;
    }
    p {
      margin: 0;
    }
  }
}

.benefices-section {
  display: flex;
  background-image: url("/img/benefices-section_bg.webp");
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  border: 0;
  gap: 100px;
  .benefices {
    @media (max-width: 769px) {
      flex-direction: column;
      height: 800px;
      img {
        width: 40%;
      }
      gap: 15px;
    }
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 350px;
    background-color: transparent;
    width: 100%;
    gap: 30px;

    .benefice {
      display: flex;
      flex: 1;
      height: 100%;
      border: 1px solid white;
      background-color: transparent;
      .icon-container {
        flex: 1;
        background-color: white;
        height: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 60%;
        }
      }
      .benefice-text {
        flex: 6;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}
.steps-section {
  height: fit-content;
  padding-top: 30vh;
  padding-bottom: 30vh;
  .step-card {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    gap: 5px;
    border: 0.5px solid white;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.521);
    .step-img {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .step-title {
      height: fit-content;
    }
    .step-text {
      height: fit-content;
    }
  }
}

.reserve-section {
  display: flex;
  align-items: center;
  background-color: #0067ec;
}
