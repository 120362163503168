ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  border-top: 1px solid white;
  background: #245aa6;
  position: relative;
}
.footer-cta {
  color: white;
  border-bottom: 1px solid white;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}
.cta-text span a {
  color: rgba(255, 255, 255, 0.8);
}
.footer-content {
  border-bottom: 1px solid white;
  position: relative;
  z-index: 2;
}

.footer-logo {
  margin-bottom: 10px;
}
.footer-logo img {
  max-width: 150px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: white;
  line-height: 28px;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #0067ec;
}
.footer-widget ul li {
  display: block;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a {
  color: white;
  text-transform: capitalize;
}

.copyright-area {
  background: #0067ec;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #d1cccc;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-section .icon {
  height: 50px;
  width: auto;
}
