.offcanvas {
  color: white;
  background-color: #3b4961;
  .offcanvas-header {
    border-bottom: 1px solid white;
  }
  .nav-item {
    border-bottom: 1px solid white;

    padding: 5px 0;
  }
}
